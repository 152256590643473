body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0;
  padding: 0;
  background-color: #00203F;
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}

h1 {
  font-size: 60px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin: 100px;
  color: #A9FBD7;
}

noscript {
  color: #A9FBD7;
  background-color: #00203F;
}